/** React library */
import React, { Component } from "react";

/** GrapeJS library */
import grapesjs from "grapesjs";

/** GrapesJS Plugins */
import plugin from "grapesjs-blocks-basic";
import gjsForms from "grapesjs-plugin-forms";
import pluginCountdown from "grapesjs-component-countdown";
import customCodePlugin from "grapesjs-custom-code";
import thePlugin from "grapesjs-plugin-export";
import pluginTooltip from "grapesjs-tooltip";
import grapesjsTouch from "grapesjs-touch";
import parserPostCSS from "grapesjs-parser-postcss";
import swiperComponent from "shared/grapesJSComponents/Swiper";
import { gjsTabs } from "shared/grapesJSComponents/TabsPlugin";
import { webpage } from "shared/grapesJSComponents/webpage";
import { styleBG } from "shared/grapesJSComponents/styleBG";
import { typedPlugin } from "shared/grapesJSComponents/TypedPlugin";
import { tuiImageEditor } from "shared/grapesJSComponents/tuiImageEditor";

/** GrapesJS components */
import {
  BSButtonPrimary,
  btnPrimary,
  componentType,
  customLinkBlock,
  LinkComponent,
} from "shared/grapesJSComponents/GrapesJSButtons";

/** GrapesJS utils */
import {
  storageManager,
  styleManager,
} from "shared/utils/grapesJS/grapesManager";

/** Project library */
import store from "features/store";
import { withRouter } from "shared/withRouter";
import { Validators } from "shared/validators/Validators";
import ImageEditor from "shared/image/ImageEditor";
import constant from "shared/utils/constant";

/** React redux */
import { connect } from "react-redux";

/** React bootstrap */
import {
  Accordion,
  Alert,
  Button,
  Form,
  Modal,
  ProgressBar,
  Spinner,
} from "react-bootstrap";

/** Service */
import { TemplateService } from "shared/services";

/** React icons */
import { AiOutlineClose } from "react-icons/ai";

/** Style */
import "./TemplateEdit.scss";
import "grapick/dist/grapick.min.css";
import { TourBG } from "shared/utils/images";
import { EmailEditor } from "react-email-editor";
import axios from "axios";

class TemplateEdit extends Component {
  constructor(props) {
    super(props);
    this.pageIs = this.props.router.params.type;
    this.emailEditorRef = React.createRef(null);
    this.isAlreadyLoaded = false;
    this.state = {
      loading: true,
      runningPreview: false,
      askConfirm: false,
      saveConfirm: false,
      tourModalShow: false,
      templateForm: {
        name: {
          value: "",
          valid: false,
          error: "",
          validators: [Validators.required()],
        },
        description: { value: "", valid: true, error: "", validators: [] },
      },
      inValidData: true,
      formValid: false,
      showInValidError: false,
      previousData: {
        name: "",
        description: "",
      },
      previewImageModalShow: false,
      previewImageLoading: false,
      previewImageAssetDetails: null,
      predefined: false,
      templateData: {},

      /** Handle final submit */
      apiLoading: false,
      templateStoringSteps: 1,
      uploadedPercentage: 0,
      failedMessage: "",
      downloadProgress: 0,
      mergeTags:
        this.pageIs === "campaign" ? constant.campaignTags : constant.mergeTags,
      eventType: [],
      tempEventCategoryList: [],
      selectedEventType: { id: "" },
      eventCategories: [],
    };
    this.editor = null;
    this.templateId = null;
  }

  componentDidMount() {
    TemplateService.getEventCategories("")
      .then((res) => {
        const data = res?.data;
        this.setState({
          eventCategories: data,
          tempEventCategoryList: data,
        });
      })
      .catch((error) => {});

    this.templateId = this.props.router.params.templateId;
    TemplateService.retrieveTemplate(this.templateId)
      .then((res) => {
        const data = res.data;
        if (!this.props.user.tenantAdmin && data.predefined) {
          this.props.router.navigate("/permission-denied");
          return;
        }
        this.setState({
          templateData: res.data,
          previousData: {
            name: data.name,
            description: data.description,
          },
          templateForm: {
            name: {
              ...this.state.templateForm.name,
              value: data.name,
              valid: true,
            },
            description: {
              ...this.state.templateForm.description,
              value: data.description,
              valid: true,
            },
          },
          inValidData: false,
          formValid: true,
          predefined: data.predefined,
        });

        if (constant.gjsRenderPageTypes.includes(this.pageIs)) {
          this.editorRender();
        } else {
          if (data?.eventType !== "" && this.pageIs === "campaign") {
            this.getEventTags(data?.eventType);
          }
          this.pageLoaded();
        }
      })
      .catch((error) => {
        this.editorRender();
      });
  }

  editorRender() {
    let plugins = [
      plugin, // gjs-blocks-basic
      thePlugin, // grapesjs-plugin-export
      grapesjsTouch, // grapesjs-touch
      parserPostCSS, // grapesjs-parser-postcss
      tuiImageEditor, // grapesjs-tui-image-editor
      styleBG, // grapesjs-style-bg
      webpage, // grapesjs-preset-webpage,
      gjsForms, // grapesjs-plugin-forms
    ];

    const pluginsOpts = {
      [plugin]: { flexGrid: true },
      [tuiImageEditor]: {
        script: [
          // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
          "https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js",
          "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js",
          "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js",
        ],
        style: [
          "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css",
          "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css",
        ],
      },
      [webpage]: {
        // blocks: ['quote', 'text-basic'],
        modalImportTitle: "Import Template",
        modalImportLabel:
          '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
        modalImportContent: (editor) => {
          return editor.getHtml() + "<style>" + editor.getCss() + "</style>";
        },
      },
    };

    if (constant.gjsRenderPageTypes.includes(this.pageIs)) {
      plugins = plugins.concat([
        swiperComponent, // swiper
        pluginCountdown, // grapesjs-component-countdown
        gjsTabs, // grapesjs-tabs
        customCodePlugin, // grapesjs-custom-code
        pluginTooltip, // grapesjs-tooltip
        // typedPlugin, // grapesjs-typed
      ]);
      pluginsOpts[gjsTabs] = { tabsBlock: { category: "Extra" } };
      pluginsOpts[typedPlugin] = {
        block: {
          category: "Extra",
          content: {
            type: "typed",
            "type-speed": 40,
            strings: ["Text row one", "Text row two", "Text row three"],
          },
        },
      };
      pluginsOpts[swiperComponent] = {
        sliderBlock: {
          label: `
                        <div>
                            <span class="material-icons material-symbols-outlined card-slider-icon">view_carousel</span>
                        </div>
                        <div>
                            Swiper
                        </div>
                        `,
          name: "cswiper",
          category: "Custom",
          attributes: {
            title: "Swiper",
          },
        },
      };
      pluginsOpts[gjsTabs] = { tabsBlock: { category: "Extra" } };
    } else {
      pluginsOpts[gjsForms] = { blocks: ["button"], category: "Basic" };
    }

    const grapesJSConfig = {
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: "#gjs",
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: true,
      // Size of the editor
      height: "100%",
      width: "auto",
      selectorManager: { componentFirst: true },
      styleManager: styleManager,
      // Show an alert before unload the page with unsaved changes
      noticeOnUnload: 0,
      plugins,
      pluginsOpts,
      storageManager: storageManager(this.templateId, this.downloadProgress),
    };

    if (constant.gjsRenderPageTypes.includes(this.pageIs)) {
      grapesJSConfig["canvas"] = {
        styles: [
          "https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css",
        ],
        scripts: [
          "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js",
          "https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.min.js",
        ],
      };
    }
    this.editor = grapesjs.init(grapesJSConfig);
    if (constant.gjsRenderPageTypes.includes(this.pageIs)) {
      this.editor.Components.addType(componentType.primary, BSButtonPrimary);
      this.editor.Blocks.add("bs-btn-primary", btnPrimary);
      this.editor.Components.addType(
        componentType.customLinkBlock,
        LinkComponent
      );
      this.editor.Blocks.add("custom-link-block-1", customLinkBlock);
    }
    // Store and load events

    this.editor.BlockManager.add("scratchcard-div", {
      label: `
        <div>
            <span class="material-icons material-symbols-outlined card-slider-icon">redeem</span>
        </div>
        <div>
            Scratch Card
        </div>
    `,
      content:
        '<div id="scratch-card-widget" style="width:500px; height:660px;"></div>',
      category: "Custom",
    });

    this.editor.on("storage:load", (e) => {
      setTimeout(() => {
        this.pageLoaded(Object.keys(e).length === 0);
      }, 1000);
    });

    this.editor.on("storage:error:load", (e) => {
      this.pageLoaded();
    });

    this.editor.on("run:preview", () => {
      window.addEventListener("beforeunload", this.keepOnPage);
      this.setState({ runningPreview: true });
      store.dispatch({ type: "SHOW_LAYOUT", payload: false });
    });

    this.editor.on("stop:preview", () => {
      this.setState({ runningPreview: false });
      store.dispatch({ type: "SHOW_LAYOUT", payload: true });
      window.removeEventListener("beforeunload", this.keepOnPage);
    });

    var commands = this.editor.Commands;

    // Update canvas-clear command
    commands.add("canvas-clear", () => {
      if (window.confirm("Are you sure to clean the canvas?")) {
        this.editor.runCommand("core:canvas-clear");
        setTimeout(() => {
          localStorage.removeItem("gjsProject");
        }, 0);
      }
    });

    this.editor.Blocks.get("text").set({
      activate: true,
      select: true,
      content: {
        type: "text",
        content: "Insert your text here",
        style: { padding: "10px" },
      },
    });

    this.editor.Blocks.get("image").set({
      activate: true,
      select: true,
      content: { type: "image" },
    });

    // Add the command
    this.editor.Commands.add("save-db", {
      run: function (editor, sender) {
        editor.store();
      },
    });

    // Do stuff on load
    this.editor.on("load", () => {
      this.editor.Panels.getButton("options", "sw-visibility").set("active", 1);
    });
  }

  keepOnPage(e) {
    var message =
      "Warning!\n\nNavigating away from this page will delete your text if you haven't already saved it.";
    e.returnValue = message;
    return message;
  }

  downloadProgress = (event) => {
    this.setState({ downloadProgress: event });
  };

  pageLoaded = (showTour = false) => {
    this.setState({ loading: false }, () => {
      if (showTour) {
        this.tourModalShow(true);
      }
    });
  };

  /**
   * After API response handle some functionality
   * @param {boolean} flag For disable loader
   * @param {boolean} redirect For close modal & redirect to home screen
   */
  isApiLoading = (flag, redirect, failedMessage = "File upload failed") => {
    this.setState(
      {
        apiLoading: flag,
        templateStoringSteps: 1,
        uploadedPercentage: 0,
        failedMessage,
      },
      () => {
        if (redirect) {
          this.closeSaveModal();
          this.cancelConfirm();
        }
      }
    );
  };

  cancelConfirm = () => {
    this.props.router.navigate("/templates/Website/templates");
  };

  askConfirmForCancel = () => {
    this.setState({
      askConfirm: true,
    });
  };

  closeCancelModal = () => {
    this.setState({
      askConfirm: false,
    });
  };

  closeSaveModal = () => {
    this.setState({
      saveConfirm: false,
      failedMessage: "",
      templateForm: {
        ...this.state.templateForm,
        name: {
          ...this.state.templateForm.name,
          value: this.state.previousData.name,
          valid: !!this.state.previousData.name,
          error: "",
        },
        description: {
          ...this.state.templateForm.description,
          value: this.state.previousData.description,
          valid: !!this.state.previousData.description,
          error: "",
        },
      },
    });
  };

  tourModalShow = (flag) => {
    if (localStorage.getItem("showTour") === null) {
      this.setState(
        {
          tourModalShow: flag,
        },
        () => {
          localStorage.setItem("showTour", "false");
        }
      );
    }
  };

  hideTourModal = () => {
    this.setState({
      tourModalShow: false,
    });
  };

  askSaveConfirm = () => {
    this.setState({
      saveConfirm: true,
    });
  };

  showEventModal = () => {
    this.setState({
      eventModalShow: true,
    });
  };

  hideEventModal = () => {
    this.setState({
      eventModalShow: false,
    });
  };

  storeDataWithTimeout = (storeFunction) => {
    try {
      setTimeout(() => {
        storeFunction()
          .then((res) => res)
          .catch((error) => error);
      }, 10);
    } catch (error) {
      storeFunction()
        .then((res) => res)
        .catch((error) => error);
    }
  };

  save = () => {
    if (this.state.formValid) {
      this.setState(
        {
          apiLoading: true,
          showInValidError: false,
          failedMessage: "",
        },
        () => {
          this.setState({
            previousData: {
              name: this.state.templateForm.name.value,
              description: this.state.templateForm.description.value,
            },
          });

          if (constant.gjsRenderPageTypes.includes(this.pageIs)) {
            this.storeDataWithTimeout(this.storeWebPageData);
          } else {
            this.storeDataWithTimeout(this.storeCampaignPageData);
          }
        }
      );
    } else {
      this.setState({
        showInValidError: true,
        failedMessage: "",
      });
    }
  };

  /* checks whether the input is valid or not */
  onInputValidation = (event, formName) => {
    let controlError = "";

    /* checks all the validators for the control */
    for (let validator of this.state[formName][event.target.name].validators) {
      controlError = validator(event.target.value);
      if (controlError) break;
    }

    /* sets the state of the control and form validity */
    this.setState(
      {
        [formName]: {
          ...this.state[formName],
          [event.target.name]: {
            ...this.state[formName][event.target.name],
            value: event.target.value,
            valid: !Boolean(controlError),
            error: controlError,
          },
        },
        inValidData: false,
      },
      () => {
        /* check form validity */
        if (controlError) {
          this.setState({ formValid: false });
        } else {
          let formValid = true;
          for (let control of Object.keys(this.state[formName])) {
            if (!this.state[formName][control].valid) {
              formValid = false;
              break;
            }
          }
          this.setState({ formValid: formValid });
        }
      }
    );
  };

  addPreviewImage = () => {
    this.setState({
      previewImageModalShow: true,
      predefined: false,
    });
  };

  makePredefineModalShow = () => {
    let { predefined } = this.state;

    if (predefined) {
      predefined = false;
      this.setState({
        previewImageModalShow: true,
      });
    } else {
      predefined = true;
    }
    this.setState({
      predefined,
    });
  };

  makePredefineModalHide = () => {
    this.setState({
      previewImageModalShow: false,
    });
  };

  uploadPredefinedImage = (event) => {
    this.setState(
      {
        previewImageLoading: true,
      },
      () => {
        const form_data = new FormData();
        form_data.append("file", event.file);
        TemplateService.uploadPredefineFile(form_data)
          .then((res) => {
            this.setState({
              previewImageAssetDetails: res.data,
              previewImageModalShow: false,
              predefined: this.state.predefined,
              previewImageLoading: false,
              templateData: {
                ...this.state.templateData,
                preview: {
                  id: res.data.id,
                  url: res.data.url,
                },
              },
            });
          })
          .catch((error) => {
            this.setState({
              previewImageLoading: false,
            });
          });
      }
    );
  };

  fileProgress = (progressEvent, secondFile = false) => {
    let percentComplete = progressEvent.loaded / progressEvent.total;
    percentComplete = parseInt(percentComplete * 100);
    this.setState({
      uploadedPercentage:
        (secondFile ? 50 : 0) +
        parseFloat(parseFloat(percentComplete / 2).toFixed(0)),
    });
  };

  storeWebPageData = () => {
    return new Promise((resolve, reject) => {
      /** GrapesJS JSON is getting. This is required when edit data */
      const projectData = this.editor.getProjectData();

      /** Getting html file and this is formatted one. */
      const pagesHtml = this.editor.Pages.getAll().map((page) => {
        const component = page.getMainComponent();
        const _page = `
                        <style>
                            ${this.editor.getCss({ component })}
                        </style>
                        ${this.editor.getHtml({ component })}
                    `;
        return { index_html: _page };
      });

      /** Component JSON File */
      const component_json = new Blob([JSON.stringify(projectData)], {
        type: "application/json",
      });
      const component_file = new File([component_json], "grapesJS.json", {
        type: "application/json",
      });

      /** HTML File */
      const html_json = new Blob([pagesHtml[0]["index_html"]], {
        type: "text/html",
      });
      const html_file = new File([html_json], "index.html", {
        type: "text/html",
      });

      /** Moving to step 2 */
      this.setState({ templateStoringSteps: 2 });

      /** Storing files */
      TemplateService.getPresignedUrl({
        entity: "TEMPLATE",
        fileExtension: "json",
      })
        .then(async (grapesJsSignedUrl) => {
          const signedUrl = grapesJsSignedUrl.data.signedUrl;
          TemplateService.cloudUpload(
            signedUrl,
            component_file,
            this.fileProgress
          )
            .then((grapesJsS3Res) => {
              const grapesJsAssetData = {
                description: `component_${this.templateId}`,
                originalFileAndExtension: "grapesJS.json",
                type: "DOCUMENT",
                url: signedUrl,
              };
              TemplateService.getAssetData(grapesJsAssetData, "TEMPLATE")
                .then((grapesJsAssetRes) => {
                  /**
                   * ==================================================
                   * ===              HTML FILE START               ===
                   * ==================================================
                   */
                  TemplateService.getPresignedUrl({
                    entity: "TEMPLATE",
                    fileExtension: "html",
                  })
                    .then(async (htmlSignedUrl) => {
                      const url = htmlSignedUrl.data.signedUrl;
                      TemplateService.cloudUpload(url, html_file, (event) =>
                        this.fileProgress(event, true)
                      )
                        .then((htmlS3Res) => {
                          const htmlAssetData = {
                            description: `html_${this.templateId}`,
                            originalFileAndExtension: "index.html",
                            type: "DOCUMENT",
                            url,
                          };
                          TemplateService.getAssetData(
                            htmlAssetData,
                            "TEMPLATE"
                          )
                            .then((htmlAssetRes) => {
                              this.setState({ templateStoringSteps: 3 });
                              const data = this.state.templateData;
                              data.predefined = this.state.predefined;
                              data.componentFileId = grapesJsAssetRes.data.id;
                              data.htmlFileId = htmlAssetRes.data.id;
                              data.name = this.state.templateForm.name.value;
                              data.description =
                                this.state.templateForm.description.value;

                              TemplateService.updateTemplate(
                                data,
                                this.templateId
                              )
                                .then((FinalRes) => {
                                  this.isApiLoading(true, true);
                                  resolve({ FinalRes });
                                })
                                .catch((error) => {
                                  this.isApiLoading(false, false);
                                  reject(error);
                                });
                            })
                            .catch((error) => {
                              this.isApiLoading(false, false);
                              reject(error);
                            });
                        })
                        .catch((error) => {
                          this.isApiLoading(false, false);
                          reject(error);
                        });
                    })
                    .catch((error) => {
                      this.isApiLoading(false, false);
                      reject(error);
                    });
                  /**
                   * ==================================================
                   * ===               HTML FILE END                ===
                   * ==================================================
                   */
                })
                .catch((error) => {
                  this.isApiLoading(false, false);
                  reject(error);
                });
            })
            .catch((error) => {
              this.isApiLoading(false, false);
              reject(error);
            });
        })
        .catch((error) => {
          this.isApiLoading(false, false);
          reject(error);
        });
    });
  };

  storeCampaignPageData = () => {
    return new Promise((resolve, reject) => {
      // section to get editor data
      this.emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        console.log("exportHtml", html);

        /** Component JSON File */
        const component_json = JSON.stringify(design);
        const component_file = new File([component_json], "grapesJS.json", {
          type: "application/json",
        });

        //let html = [pagesHtml[0]['index_html']]

        /** HTML File */
        const html_json = html; // new Blob(html, { type: 'text/html' });
        // const html_json =
        const html_file = new File([html_json], "index.html", {
          type: "text/html",
        });
        /** Moving to step 2 */
        this.setState({ templateStoringSteps: 2 });

        /** Storing files */
        TemplateService.getPresignedUrl({
          entity: "TEMPLATE",
          fileExtension: "json",
        })
          .then(async (grapesJsSignedUrl) => {
            const signedUrl = grapesJsSignedUrl.data.signedUrl;
            TemplateService.cloudUpload(
              signedUrl,
              component_file,
              this.fileProgress
            )
              .then((grapesJsS3Res) => {
                const grapesJsAssetData = {
                  description: `component_${this.templateId}`,
                  originalFileAndExtension: "grapesJS.json",
                  type: "DOCUMENT",
                  url: signedUrl,
                };
                TemplateService.getAssetData(grapesJsAssetData, "TEMPLATE")
                  .then((grapesJsAssetRes) => {
                    /**
                     * ==================================================
                     * ===              HTML FILE START               ===
                     * ==================================================
                     */
                    TemplateService.getPresignedUrl({
                      entity: "TEMPLATE",
                      fileExtension: "html",
                    })
                      .then(async (htmlSignedUrl) => {
                        const url = htmlSignedUrl.data.signedUrl;
                        TemplateService.cloudUpload(url, html_file, (event) =>
                          this.fileProgress(event, true)
                        )
                          .then((htmlS3Res) => {
                            const htmlAssetData = {
                              description: `html_${this.templateId}`,
                              originalFileAndExtension: "index.html",
                              type: "DOCUMENT",
                              url,
                            };
                            TemplateService.getAssetData(
                              htmlAssetData,
                              "TEMPLATE"
                            )
                              .then((htmlAssetRes) => {
                                this.setState({ templateStoringSteps: 3 });
                                const data = this.state.templateData;
                                data.predefined = this.state.predefined;
                                data.componentFileId = grapesJsAssetRes.data.id;
                                data.htmlFileId = htmlAssetRes.data.id;
                                data.name = this.state.templateForm.name.value;
                                data.description =
                                  this.state.templateForm.description.value;
                                if (this.state?.selectedEventType?.id) {
                                  data.eventType =
                                    this.state?.selectedEventType.id;

                                  if (data.templateType !== "REFER_A_FRIEND")
                                    data.templateType = "MESSAGE_TEMPLATE";
                                }
                                TemplateService.updateTemplate(
                                  data,
                                  this.templateId
                                )
                                  .then((FinalRes) => {
                                    this.isApiLoading(true, true);
                                    resolve({ FinalRes });
                                  })
                                  .catch((error) => {
                                    this.isApiLoading(false, false);
                                    reject(error);
                                  });
                              })
                              .catch((error) => {
                                this.isApiLoading(false, false);
                                reject(error);
                              });
                          })
                          .catch((error) => {
                            this.isApiLoading(false, false);
                            reject(error);
                          });
                      })
                      .catch((error) => {
                        this.isApiLoading(false, false);
                        reject(error);
                      });
                    /**
                     * ==================================================
                     * ===               HTML FILE END                ===
                     * ==================================================
                     */
                  })
                  .catch((error) => {
                    this.isApiLoading(false, false);
                    reject(error);
                  });
              })
              .catch((error) => {
                this.isApiLoading(false, false);
                reject(error);
              });
          })
          .catch((error) => {
            this.isApiLoading(false, false);
            reject(error);
          });
      });
    });
  };

  onReady = async () => {
    if (this.isAlreadyLoaded || !this.state?.templateData?.componentFile?.url) {
      return;
    }
    // editor is ready
    // you can load your template here;
    // const templateJson = {};

    const S3Data = await axios.get(
      this.state?.templateData?.componentFile.url,
      {
        onDownloadProgress: async (progressEvent) => {
          const total = parseFloat(progressEvent.total);
          const current = progressEvent.loaded;
          let percentCompleted = Math.floor((current / total) * 100);
          this.downloadProgress(percentCompleted);
        },
      }
    );
    if (S3Data.data) {
      this.emailEditorRef.current.editor.loadDesign(S3Data.data);
      this.isAlreadyLoaded = true;
    }
  };

  exportEmailHtml = () => {
    this.emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      console.log("design", design);
    });
  };

  onCangeEvent = (e) => {
    let searchText = e.target.value;
    let { eventCategories, tempEventCategoryList } = this.state;

    if (searchText) {
      let value = searchText.toLowerCase();
      eventCategories = tempEventCategoryList.filter((event) =>
        event?.description.toLowerCase().includes(value)
      );
    } else {
      eventCategories = tempEventCategoryList;
    }
    this.setState({
      eventCategories,
    });
  };

  getEventTags = (id) => {
    let _this = this;
    TemplateService.getEventList(id)
      .then((res) => {
        const data = res?.data;
        const attributes = data?.attributes;
        let campaignEventTags = {};
        attributes.forEach((att) => {
          Object.assign(campaignEventTags, {
            [att]: {
              name: att,
              value: `{{ ${att} }}`,
              sample: `{{ ${att} }}`,
            },
          });
        });
        _this.setState(
          {
            mergeTags: campaignEventTags,
            selectedEventType: data,
          },
          () => {
            _this.emailEditorRef.current.editor.setMergeTags(campaignEventTags);
          }
        );
      })
      .catch((error) => {});
  };

  getEditorDisplayMode() {
    if (this.pageIs === "campaign") {
      return "email";
    }

    return "popup";
  }

  renderEditor() {
    if (constant.gjsRenderPageTypes.includes(this.pageIs)) {
      return (
        <div
          className={`grapesJS-panel-layout h-100 ${
            this.state.loading ? "invisible" : ""
          }`}
        >
          <div className="editor-row">
            <div className="d-flex w-100">
              <div className={`editor-canvas h-100`}>
                <div id="gjs"></div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <EmailEditor
        options={{
          mergeTags: this.state.mergeTags,
          displayMode: this.getEditorDisplayMode(),
        }}
        minHeight={"100%"}
        ref={this.emailEditorRef}
        onReady={() => this.onReady()}
      />
    );
  }

  render() {
    return (
      <div className="h-100 template-edit">
        {/* Loading icon */}
        <div
          className={`page-loading h-100 ${
            this.state.loading ? "block" : "d-none"
          }`}
        >
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div
              className="spinner-border text-danger mb-2"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <ProgressBar
              className="download-progress"
              variant="info"
              animated
              now={this.state.downloadProgress}
            />
          </div>
        </div>

        {/* Buttons section */}
        <div
          className={`d-flex justify-content-end align-items-center ${
            this.state.loading ? "invisible" : ""
          }`}
        >
          {this.pageIs === "campaign" && (
            <div className="">
              <Button
                className="event-button"
                style={{}}
                variant="info"
                size="sm"
                onClick={this.showEventModal}
              >
                {this.state.selectedEventType?.name
                  ? this.state.selectedEventType?.name
                  : "Notification"}
              </Button>
            </div>
          )}
          {this.props.user.tenantAdmin &&
            this.state.selectedEventType?.id === "" && (
              <div className="ms-2">
                <Button
                  style={{ width: "150px" }}
                  variant="success"
                  size="sm"
                  onClick={this.makePredefineModalShow}
                >
                  {this.state.predefined ? "Remove" : "Save as "} Template
                </Button>
              </div>
            )}

          <div className="ms-2">
            <Button
              style={{ width: "150px" }}
              variant="primary"
              size="sm"
              onClick={this.askSaveConfirm}
            >
              Save
            </Button>
          </div>
          <div className="ms-2">
            <Button
              style={{ width: "150px" }}
              variant="secondary"
              size="sm"
              onClick={this.askConfirmForCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="ms-2">
            <Button
              style={{ width: "150px" }}
              variant="secondary"
              size="sm"
              onClick={this.addPreviewImage}
            >
              Add Preview Image
            </Button>
          </div>
        </div>

        {/* GrapesJS template */}
        {this.renderEditor()}

        {/* Save modal box */}
        <Modal
          show={this.state.saveConfirm}
          onHide={this.closeSaveModal}
          centered
          backdrop={"static"}
          keyboard={false}
        >
          <Modal.Body>
            <div className="save-confirm-modal py-2">
              <div className="text-center">
                <span className="warning-icon">&#9432;</span>
              </div>
              <div className="save-text text-center mb-2">
                Save your Changes
              </div>
              {this.state.showInValidError ? (
                <Alert variant="danger" className="text-center">
                  Please enter template name
                </Alert>
              ) : null}
              {this.state.apiLoading ? (
                <div>
                  {this.state.templateStoringSteps === 1 ? (
                    <div className="file-generating">
                      <div className="text-center fs-4 fw-light">
                        <span className="me-2">
                          Please wait template generating
                        </span>
                        <Spinner
                          variant="info"
                          as={"span"}
                          animation="border"
                          role={"status"}
                          size="sm"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  ) : this.state.templateStoringSteps === 2 ? (
                    <div className="file-uploading">
                      <div className="text-center fs-4 fw-light mb-2">
                        Template upload inprogress
                      </div>
                      <ProgressBar
                        animated
                        variant="success"
                        now={this.state.uploadedPercentage}
                        label={`${this.state.uploadedPercentage}%`}
                      />
                    </div>
                  ) : (
                    <div className="saving data text-center fs-4 fw-light">
                      <span className="me-2">Please wait... </span>
                      <Spinner
                        variant="info"
                        as={"span"}
                        animation="border"
                        role={"status"}
                        size="sm"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <Form>
                  <Form.Group className="mb-3" controlId="templateName">
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={this.state.templateForm.name.value}
                      onChange={(event) =>
                        this.onInputValidation(event, "templateForm")
                      }
                      placeholder="Template Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="templateDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={this.state.templateForm.description.value}
                      onChange={(event) =>
                        this.onInputValidation(event, "templateForm")
                      }
                      placeholder="Template Description"
                    />
                  </Form.Group>
                </Form>
              )}
              {this.state.failedMessage ? (
                <div className="text-center text-danger mb-3">
                  {this.state.failedMessage}
                </div>
              ) : null}
              {!this.state.apiLoading && (
                <div className="buttons-container text-center">
                  <Button
                    variant="secondary"
                    onClick={this.closeSaveModal}
                    disabled={this.state.apiLoading}
                  >
                    Cancel
                  </Button>
                  {this.state.apiLoading ? (
                    <Button variant="primary" disabled={true}>
                      <span className="d-flex align-items-center justify-content-center">
                        <span>OK&nbsp;</span>{" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={this.save}>
                      OK
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {/* Cancel modal box */}
        <Modal
          show={this.state.askConfirm}
          onHide={this.closeCancelModal}
          centered
        >
          <Modal.Body>
            <div className="template-cancel-confirm text-center py-2">
              <div>
                <span className="warning-icon">&#9432;</span>
              </div>
              <div className="h2 mb-4">Are you sure?</div>
              <div className="h6 mb-4">
                you want to exit? All unsaved work will be lost
              </div>
              <div className="buttons-container text-center">
                <Button variant="secondary" onClick={this.closeCancelModal}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.cancelConfirm}>
                  OK
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Tour modal */}
        <Modal show={this.state.tourModalShow} className="tour-modal" centered>
          <Modal.Body
            className="rounded-4 p-0"
            style={{ backgroundImage: `url(${TourBG})` }}
          >
            <div className="tour-content rounded-4 p-3">
              <div
                className="close-icon clickable"
                onClick={() => {
                  this.hideTourModal();
                }}
              >
                <AiOutlineClose />
              </div>
              <div className="p-5">
                <h3 className="text-white mb-0">
                  Drag & Drop your element here to build your page
                </h3>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Preview Image modal */}
        {this.state.previewImageModalShow ? (
          <ImageEditor
            show={this.state.previewImageModalShow}
            onCancel={this.makePredefineModalHide}
            onComplete={this.uploadPredefinedImage}
            inProgress={this.state.previewImageLoading}
            size={{ width: 1200, height: 720 }}
            usePreviousImage={this.state.templateData?.preview?.url}
          />
        ) : null}

        {/* Event Type modal */}
        <Modal show={this.state.eventModalShow} centered size="lg">
          <Modal.Body className="rounded-4 p-0">
            <div className="tour-content rounded-4 p-3">
              <div style={{ fontWeight: "bold" }}>Event Category</div>
              <div
                className="close-icon clickable"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: "15px",
                }}
                onClick={() => {
                  this.hideEventModal();
                }}
              >
                <AiOutlineClose />
              </div>
              <div className="p-5 pt-3">
                <input
                  style={{ width: "100%", height: "40px" }}
                  type="text"
                  placeholder="Search by event name"
                  onChange={this.onCangeEvent}
                />
                {this.state.eventCategories.length > 0 && (
                  <div
                    style={{
                      height: "500px",
                      overflowY: "scroll",
                    }}
                  >
                    {this.state.eventCategories.map((event, index) => {
                      let events = event.events;
                      if (event.children.length > 0) {
                        events = event.children?.[0].events;
                      }

                      return (
                        <Accordion
                          key={index}
                          style={{
                            border: "none",
                            borderBottom: "1px solid #dedede",
                          }}
                          className="notification-accordion"
                        >
                          <Accordion.Item
                            eventKey={event.description}
                            style={{ border: "none" }}
                          >
                            <Accordion.Header>
                              <div className="d-flex justify-content-start align-items-center">
                                <div
                                  style={{ width: "100%", paddingLeft: "2px" }}
                                >
                                  {event.description}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ padding: 0 }}>
                              <div className="d-flex justify-content-start align-items-center">
                                {events?.length > 0 && (
                                  <div style={{ width: "100%" }}>
                                    {events.map((eve, index) => {
                                      return (
                                        <div
                                          className="d-flex align-items-center"
                                          style={{
                                            cursor: "pointer",
                                            border: "1px solid grey",
                                            padding: "10px 10px 10px 0px",
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              selectedEventType: eve,
                                            });
                                            this.getEventTags(eve.id);
                                            this.hideEventModal();
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              paddingLeft: "5px",
                                            }}
                                          >
                                            {eve.name}
                                          </div>
                                          <div>{">"}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(TemplateEdit));
