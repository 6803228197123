const constant = {
  POPUP: "POPUP",
  OFFERS: "OFFERS",
  VOUCHER: "VOUCHER",
  NOTIFICATION: "MESSAGE_TEMPLATE",
  SCRATCHCARD: "SCRATCHCARD",
  CONTEST: "CONTEST",
  LANDINGPAGE: "LANDING_PAGE",
  REFERAFRIEND: "REFER_A_FRIEND",
  mergeTags: {
    age: {
      name: "Age",
      value: "{{ age }}",
      sample: "{{ age }}",
    },
    birthdate: {
      name: "Birthdate",
      value: "{{ birthdate }}",
      sample: "{{ birthdate }}",
    },
    cohoraUrl: {
      name: "Cohora url",
      value: "{{ cohoraUrl }}",
      sample: "{{ cohoraUrl }}",
    },
    email: {
      name: "Email",
      value: "{{ email }}",
      sample: "{{ email }}",
    },
    name: {
      name: "Name",
      value: "{{ name }}",
      sample: "{{ name }}",
    },
    nickname: {
      name: "Nickname",
      value: "{{ nickname }}",
      sample: "{{ nickname }}",
    },
    phoneNumber: {
      name: "Phone number",
      value: "{{ phoneNumber }}",
      sample: "{{ phoneNumber }}",
    },
    preferredLanguage: {
      name: "Preferred language",
      value: "{{ preferredLanguage }}",
      sample: "{{ preferredLanguage }}",
    },
    profileUrl: {
      name: "Profile url",
      value: "{{ profileUrl }}",
      sample: "{{ profileUrl }}",
    },
    referralUrl: {
      name: "ReferralUrl url",
      value: "{{ referralUrl }}",
      sample: "{{ referralUrl }}",
    },
    surname: {
      name: "Surname",
      value: "{{ surname }}",
      sample: "{{ surname }}",
    },
    tenantName: {
      name: "Tenant name url",
      value: "{{ tenantName }}",
      sample: "{{ tenantName }}",
    },
    walletBalance: {
      name: "Wallet balance",
      value: "{{ walletBalance }}",
      sample: "{{ walletBalance }}",
    },
    walletCurrency: {
      name: "Wallet currency",
      value: "{{ walletCurrency }}",
      sample: "{{ walletCurrency }}",
    },
    walletFormattedBalance: {
      name: "Wallet formatted balance",
      value: "{{ walletFormattedBalance }}",
      sample: "{{ walletFormattedBalance }}",
    },
  },
  campaignTags: {
    age: {
      name: "Age",
      value: "{{ age }}",
      sample: "{{ age }}",
    },
    birthdate: {
      name: "Birthdate",
      value: "{{ birthdate }}",
      sample: "{{ birthdate }}",
    },
    cohoraUrl: {
      name: "Cohora url",
      value: "{{ cohoraUrl }}",
      sample: "{{ cohoraUrl }}",
    },
    email: {
      name: "Email",
      value: "{{ email }}",
      sample: "{{ email }}",
    },
    name: {
      name: "Name",
      value: "{{ name }}",
      sample: "{{ name }}",
    },
    nickname: {
      name: "Nickname",
      value: "{{ nickname }}",
      sample: "{{ nickname }}",
    },
    phoneNumber: {
      name: "Phone number",
      value: "{{ phoneNumber }}",
      sample: "{{ phoneNumber }}",
    },
    preferredLanguage: {
      name: "Preferred language",
      value: "{{ preferredLanguage }}",
      sample: "{{ preferredLanguage }}",
    },
    profileUrl: {
      name: "Profile url",
      value: "{{ profileUrl }}",
      sample: "{{ profileUrl }}",
    },
    referralUrl: {
      name: "ReferralUrl url",
      value: "{{ referralUrl }}",
      sample: "{{ referralUrl }}",
    },
    surname: {
      name: "Surname",
      value: "{{ surname }}",
      sample: "{{ surname }}",
    },
    tenantName: {
      name: "Tenant name url",
      value: "{{ tenantName }}",
      sample: "{{ tenantName }}",
    },
    unsubscribe: {
      name: "Unsubscribe",
      value: "{{ unsubscribeUrl }}",
      sample: "{{ unsubscribeUrl }}",
    },
    walletBalance: {
      name: "Wallet balance",
      value: "{{ walletBalance }}",
      sample: "{{ walletBalance }}",
    },
    walletCurrency: {
      name: "Wallet currency",
      value: "{{ walletCurrency }}",
      sample: "{{ walletCurrency }}",
    },
    walletFormattedBalance: {
      name: "Wallet formatted balance",
      value: "{{ walletFormattedBalance }}",
      sample: "{{ walletFormattedBalance }}",
    },
  },

  gjsRenderPageTypes: ["website", "referafriend", "scratchcard"],
};
export default constant;
