import { Component } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { withRouter } from "shared/withRouter";
import store from "features/store";
import { TemplateService } from "shared/services";
import { Spinner } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";

import "./TemplateList.scss";
import constant from "shared/utils/constant";
import { ChoseEmailOrPageModal } from "./ChoseEmailOrPage";

class TemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: "",
      activeTab: "",
      createApiLoading: false,

      emailOrPageModalProps: {
        show: false,
      },
    };
  }

  componentDidMount() {
    this.updateActivePage();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      this.updateActivePage();
    }
  }

  updateActivePage = () => {
    const current_path = this.props.router.location.pathname.split("/");
    this.setState({
      activeTab: current_path.pop(),
      activePage: current_path.pop(),
    });
  };

  /**
   * Navigate to child template list page
   * @param { Event } event Click event - For disable default a tag click function.
   * @param {'Campaign' | 'Website'} flag Page name
   */
  changePage = (event, flag) => {
    event.preventDefault();
    this.setPageHeight();
    this.props.router.navigate(`/templates/${flag}/templates`);
  };

  /**
   *
   * @param {Event} event
   * @param {'templates' | 'saved-templates', | 'notification-tamplates'} flag
   */
  changeTab = (event, flag) => {
    event.preventDefault();
    this.setPageHeight();
    this.props.router.navigate(`/templates/${this.state.activePage}/${flag}`);
  };

  setPageHeight = () => {
    store.dispatch({ type: "TEMPLATE_LIST_SCREEN_HEIGHT", payload: true });
  };

  getTemplateType = (activePage) => {
    switch (activePage) {
      case "campaign":
        return "CAMPAIGN";
      case "popup":
        return "POP_UP";
      case "offers":
        return "VOUCHER";
      case "notification":
        return "CAMPAIGN";
      case "scratchcard":
        return constant.SCRATCHCARD;
      case "referafriend":
        return constant.REFERAFRIEND;
      default:
        return "LANDING_PAGE";
    }
  };

  generateTemplatePayload = (activePage, eventType) => ({
    name: "Template",
    description: "",
    predefined: false,
    eventType,
    templateType: this.getTemplateType(activePage),
    preview: { id: "", url: "" },
    modules: [],
    componentFileId: "",
    htmlFileId: "",
  });

  hideEmailOrPageModal = () => {
    this.setState({ emailOrPageModalProps: { show: false } });
  };

  handleCreateTemplate = () => {
    const activePage = this.state.activePage.toLowerCase();

    if (activePage === "referafriend") {
      return this.setState({
        emailOrPageModalProps: {
          show: true,
          onEmailClick: () =>
            this.newTemplate(
              activePage,
              this.generateTemplatePayload(
                activePage,
                "REFER_FRIEND_PAGE_EMAIL_LINK"
              ),
              "campaign"
            ),
          onPageClick: () =>
            this.newTemplate(
              activePage,
              this.generateTemplatePayload(activePage)
            ),
        },
      });
    }

    if (activePage === "scratchcard") {
      return this.newTemplate(
        activePage,
        this.generateTemplatePayload(activePage)
      );
    }
  };

  newTemplate = (activePage, data, redirect) => {
    this.setState(
      {
        createApiLoading: true,
      },
      () => {
        TemplateService.createNewTemplate(data)
          .then((res) => {
            this.props.router.navigate(
              `/template/${redirect || activePage}/edit/${res.data.id}`,
              { state: { tab: this.props.router.params.tab } }
            );
          })
          .catch((error) => {
            this.setState({
              createApiLoading: false,
            });
          });
      }
    );
  };

  render() {
    return (
      <div className="template-list-container h-100">
        <section className="tabs-section d-flex align-items-end justify-content-center">
          <div className="large-screen-width">
            <div className="px-3 d-flex align-items-start justify-content-between">
              <div className="d-flex align-items-center ">
                <div
                  className={`tab ${
                    this.state.activeTab === "templates" ? "active" : ""
                  }`}
                >
                  <a
                    href={`/templates/${this.state.activeTab}/templates`}
                    onClick={(event) => this.changeTab(event, "templates")}
                  >
                    <div>
                      {this.state.activePage.toLowerCase() === "website" &&
                        "Website Templates"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "campaign" &&
                        "Campaign Templates"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "popup" &&
                        "Popup Templates"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "offers" &&
                        "Offers Templates"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "notification" &&
                        "Notification Templates"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "referafriend" &&
                        "Refer-a-Friend Templates"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "scratchcard" &&
                        "Scratchcard Templates"}
                    </div>
                    {/* <div>{this.state.activePage.toLowerCase() === 'contest' && 'Contest Templates'}</div> */}
                  </a>
                </div>
                <div
                  className={`tab ${
                    this.state.activeTab === "saved-templates" ? "active" : ""
                  }`}
                >
                  <a
                    href={`/templates/${this.state.activeTab}/saved-templates`}
                    onClick={(event) =>
                      this.changeTab(event, "saved-templates")
                    }
                  >
                    <div>
                      {this.state.activePage.toLowerCase() === "website" &&
                        "Website Library"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "campaign" &&
                        "Campaign Library"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "popup" &&
                        "Popup Library"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "offers" &&
                        "Offers Library"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "notification" &&
                        "Notification Library"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "referafriend" &&
                        "Refer-a-Friend Library"}
                    </div>
                    <div>
                      {this.state.activePage.toLowerCase() === "scratchcard" &&
                        "Scratchcard Library"}
                    </div>
                    {/* <div>{this.state.activePage.toLowerCase() === 'contest' && 'Contest Library'}</div> */}
                  </a>
                </div>
              </div>
              <div>
                {this.state.createApiLoading ? (
                  <button className="btn create-new text-white" disabled={true}>
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      style={{ width: "1rem", height: "1rem" }}
                    />
                  </button>
                ) : (
                  <button
                    className="btn create-new text-white"
                    onClick={this.handleCreateTemplate}
                  >
                    <span className="d-none d-md-block">Create New</span>
                    <span className="d-block d-md-none">
                      <IoMdAdd className="h4 mb-0 text-white" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={`outlet-section bg-light`}>
          <div
            className={`d-flex align-items-center justify-content-center  ${
              this.props.templateListHeightIsFull ? "h-100" : ""
            }`}
          >
            <div className="large-screen-width">
              <Outlet></Outlet>
            </div>
          </div>
        </section>
        <ChoseEmailOrPageModal
          onHide={this.hideEmailOrPageModal}
          {...this.state.emailOrPageModalProps}
        />
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(TemplateList));
